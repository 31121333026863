<template>
  <!-- element表格自定义封装 带分页组件 -->
  <div>
    <el-table
        stripe
        border
        ref="table"
        v-loading="loading"
        element-loading-text="拼命加载中..."
        :width="width"
        :height="height"
        :max-height="maxHeight"
        :show-header="showHeader"
        :fit="fit"
        :size="size"
        :data="tableData"
        :header-row-class-name="headerRowClassName"
        :header-row-style="headerRowStyle"
        :header-cell-class-name="headerCellClassName"
        :header-cell-style="headerCellStyle"
        :row-class-name="rowClassName"
        :row-style="rowStyle"
        :cell-class-name="cellClassName"
        :cell-style="cellStyle"
        @select="selectCheck"
        @select-all="selectAll"
        @selection-change="handleSelectionChange"
        @cell-dblclick="doubleClick"
        row-key="id"
        :tree-props="{children: 'children'}"
    >
      <template v-for="(item, index) in columns">
        <a-f-table-column
            v-if="item.isShow ?? item.auths"
            :key="index"
            :prop="item.prop"
            :type="item.type ?? undefined"
            :label="item.label"
            :width="item.width"
            :min-width="item.minWidth"
            :fixed="item.fixed"
            :align="item.align || 'left'"
            :header-align="item.headerAlign || 'left'"
            :show-overflow-tooltip="item.tooltip || false"
            :sortable="item.sortable || false"
            :class-name="item.className"
            :label-class-name="item.labelClassName">
          <!-- 利用slot插槽 自定义列 -->
          <template #default="scope" v-if="item.slot">
            <slot :name="item.slot" :scope="scope"></slot>
          </template>
        </a-f-table-column>

        <!--<el-table-column-->
        <!--    v-if="item.isShow ?? item.auths"-->
        <!--    :key="index"-->
        <!--    :prop="item.prop"-->
        <!--    :type="item.type ?? undefined"-->
        <!--    :label="item.label"-->
        <!--    :width="item.width"-->
        <!--    :min-width="item.minWidth"-->
        <!--    :fixed="item.fixed"-->
        <!--    :align="item.align || 'center'"-->
        <!--    :header-align="item.headerAlign || 'center'"-->
        <!--    :show-overflow-tooltip="item.tooltip || false"-->
        <!--    :sortable="item.sortable || false"-->
        <!--    :class-name="item.className"-->
        <!--    :label-class-name="item.labelClassName"-->
        <!--&gt;-->
        <!--  &lt;!&ndash; 利用slot插槽 自定义列 &ndash;&gt;-->
        <!--  <template #default="scope" v-if="item.slot">-->
        <!--    <slot :name="item.slot" :scope="scope"></slot>-->
        <!--  </template>-->
        <!--</el-table-column>-->
      </template>
    </el-table>
    <!--分页组件-->
    <el-pagination
        v-if="Object.keys(this.customsFromWhere).length > 0"
        style="text-align: center; margin-top: 20px"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="customsFromWhere.page_size"
        :current-page="customsFromWhere.page"
        @size-change="changeSize"
        @current-change="handleCurrentChange"
        background>
    </el-pagination>
  </div>
</template>

<script>
import optionGroup from "view-design/src/components/option-group";

import AFTableColumn from 'af-table-column'

export default {
  name: "MyTable",
  props: {
    // 加载动画
    loading: {
      type: Boolean,
      default: false
    },
    // 表格宽度
    width: [String, Number],
    // 表格高度
    height: [String, Number],
    // 表格最大高度
    maxHeight: [String, Number],
    // 显示表头
    showHeader: {
      type: Boolean,
      default: true
    },
    // 列的宽度是否自撑开
    fit: {
      type: Boolean,
      default: true
    },
    // Table 的尺寸 medium/small/mini
    size: {
      type: String
    },
    // 表头数据
    columns: {
      type: Array
    },
    // 表格数据
    tableData: {
      type: Array,
      default: () => []
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件	参数selection, row
    select: {
      type: [Function]
    },
    // 表头行的 className 的回调方法 Function({row, rowIndex})/String
    headerRowClassName: {
      type: [Function, String]
    },
    // 表头行的 style 的回调方法 Function({row, rowIndex})/Object
    headerRowStyle: {
      type: [Function, Object],
      default: () => ({})
    },
    // 表头单元格的 className 的回调方法 Function({row, column, rowIndex, columnIndex})/String
    headerCellClassName: {
      type: [Function, String]
    },
    // 表头单元格的 style 的回调方法 Function({row, column, rowIndex, columnIndex})/Object
    headerCellStyle: {
      type: [Function, Object],
      default: () => ({})
    },
    // 行的 className 的回调方法 Function({row, rowIndex})/String
    rowClassName: {
      type: [Function, String]
    },
    // 行的 style 的回调方法 Function({row, rowIndex})/Object
    rowStyle: {
      type: [Function, Object],
      default: () => ({})
    },
    // 单元格的 className 的回调方法 Function({row, column, rowIndex, columnIndex})/String
    cellClassName: {
      type: [Function, String]
    },
    // 单元格的 style 的回调方法 Function({row, column, rowIndex, columnIndex})/Object
    cellStyle: {
      type: [Function, Object],
      default: () => ({})
    },
    // 总条目数
    total: {
      type: [String, Number],
      default: 0
    },
    // 分页器 当前页数、每页显示条目个数
    customsFromWhere: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {}
  },
  components:{
    AFTableColumn
  },

  created() {},
  methods: {
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    selectCheck(selection, row) {
      this.$emit("select", selection, row)
    },
    // 表格全选
    selectAll(list){
      // console.log('点击全选')
      // console.log(list)
      this.$emit("selectAll", list)
    },
    //实现单选方法
    handleSelectionChange(val){
      this.$emit("selection-change", val)
    },
    // 当前页数改变事件
    handleCurrentChange(val) {
      this.$emit("currentChange", val)
    },
    changeSize(val){
      console.log(val)
      this.$emit("ChangeSize", val)
    },
    //双击单元格
    doubleClick(row, column){
      this.$emit("doubleClick", row,column)
    },

    getKey(row){
      console.log(row.id)
      return row.id
    }

  }
}
</script>
